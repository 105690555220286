var $ = require("jquery");
import UIkit from 'uikit';
var breakpoint = require('./app-breakpoints');
var _gridSize = breakpoint().match(/(\d+)/g);
var _winSize = $(window).width();
var	_gridLabel = breakpoint().replace(/\d+/g, '').replace(" ", "").replace(/\"/g, "");


$(function() {
	$(document).ready(function(){
		var slideshow = UIkit.slideshow('#accueil');

		// Variable with element that fire event
		var $slideItem = $('#accueil .uk-slideshow-items > div');

		$slideItem.on('beforeitemshow', function(){
			//UIkit.notification({ message: 'Fire Before Item Show', timeout:1000 });
			$('.vy_slider .vy_slider_nav .numbering span').text($(this).find('.item').attr('data-item'));
		});
		$slideItem.on('itemshown', function(){
			//UIkit.notification({ message: 'Item shown', timeout:1000 });
		});
	});

	// $(window).scroll(function() {
	// 	if(_gridLabel != "smartphone") {
		
	// 	}
	// });


	$('#vy_menu_primary li a, .target-to').click(function() {
		console.log($(this).attr('href').split('#')[1]);
		$([document.documentElement, document.body]).animate({
				scrollTop: $('#'+$(this).attr('href').split('#')[1]).offset().top - 70
		}, 1000);;
	});

	$(document).scroll(function() {
		var y = $(this).scrollTop();
		if (y > 241) {
			$('header').addClass('minimized');
		} else {
			$('header').removeClass('minimized');
		}
	});

	
	require('./app-map');


    $(window).resize(function(){
		_gridSize = breakpoint().match(/(\d+)/g);
		_winSize = $(window).width();
		//_margin = (_winSize - _gridSize) / 2;

		_gridLabel = breakpoint().replace(/\d+/g, '').replace(' ','');

		if(_gridLabel == "smartphone") {
		
		}

	}).resize();

});